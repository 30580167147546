.DocumentPreview
{
    margin-top:2rem
}
#DocumentPage .pg-viewer-wrapper
{
    background: #d7d7d7;
    padding: 40px;
    height: 50rem;
}
#DocumentPage .pg-viewer
{
    padding:40px
}
#DocumentPage .DocTopBar{

    margin-bottom: 2rem;
    background: #57c32b;
    padding: 10px ;
    color: #fff;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    margin:0px 1px 20px 1px;
}
#DocumentPage .docTitle{
    font-size: 1.2rem;
    color: #fff;
    margin:0px;
    padding-top: 0.3rem;
}
#DocumentPage .docTitle i{
    padding-left: 5px;
    font-size: 1.5rem;
}
.DocumentFields
{margin-bottom: 2rem;}

.DocumentFields .lblBold{
    color:#57c32b;
    font-size: 0.8rem;
}
.DocumentFields .lblBold i{
    padding-left: 5px;
    font-size: 1rem;
}
.DocumentFields  .filedBox{
    margin-bottom: 10px;
}
a.btn-simple
{
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
    border-radius: 7px;
}
#DocumentPage .DocTopBar .btn-simple{
    color: #fff;
    padding: 0.7rem;
    margin: 0px 5px;
}
#DocumentPage .DocTopBar .btn-simple:hover{
color:#fff;
box-shadow: none;
background-color: #027450 !important;
}
#DocumentPage .DocTopBar .tooltiptext {
    top: 120%;
  }
#notes
{
    margin-top: 2rem;
}
.fontSmall
{
    font-size: 0.9rem;
    margin-right: 5px;
}
.EnFontSmall{
    font-family: tahoma;
    font-size: 1.2rem;
}