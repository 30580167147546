#allMeterTree .SelectedItem {
  background: #bfbcbc;
  -webkit-transition: all 0.3ms;
  -moz-transition: all 0.3ms;
  transition: all 0.3ms;
  color: #fff;
}
#allMeterTree .SelectedItem:hover {
  background: #bfbcbc;
  background-color: #bfbcbc;
}
#allMeterTree .SelectedItem span,
#allMeterTree .SelectedItem a span {
  color: #fff !important;
}
#allMeterTree .SelectedItem span > i,
#allMeterTree .SelectedItem a span > i {
  color: #333 !important;
}
#allMeterTree .Navbox.SelectedItem:hover {
  background-color: #bfbcbc;
}
#allMeterTree .nav-link[data-toggle].collapsed:before {
  content: ' ▾';
  padding-left: 3px;
  font-size: 1.2rem;
  color: #a5a5a5;
  position: relative;
  top: -6px;
  right: -12px;
  padding: 10px;
}
#allMeterTree .nav-link[data-toggle]:not(.collapsed):before {
  content: ' ▴';
  padding-left: 3px;
  font-size: 1.2rem;
  color: #a5a5a5;
  position: relative;
  top: -6px;
  right: -12px;
  padding: 10px;
}
#allMeterTree .nav-item > li {
  padding: 10px 0px;
}
#allMeterTree .nav-item > li > .nav-item > li {
  padding: 0px 0px;
}
#allMeterTree .nav-item a {
  display: inline-block;
}
#allMeterTree .nav-item i {
  padding-left: 0.5rem;
}
#allMeterTree .nav-item span {
  font-size: 1rem;
  color: #333;
}
#allMeterTree .nav-item {
  margin: 3px 0px;
}
#allMeterTree .navClick {
  display: inline-block;
  cursor: context-menu;
  padding-left: 0.5rem;
}
#allMeterTree .Navbox {
  padding: 5px;
}
#allMeterTree .Navbox:hover {
  background-color: #f9f9f9;
}
#allMeterTree .nav-item a,
#allMeterTree .nav-item a span {
  font-size: 0.93em;
  color: #333;
  width: 90%;
}
#allMeterTree .nav-item span {
  font-size: 0.93rem !important;
}
#allMeterTree .nav-item .nav-link {
  padding: 0rem;
  height: 10px;
  width: 10px;
}
#allMeterTree .nav-item i.red {
  color: red;
}
#allMeterTree .nav-item i.orange {
  color: rgb(255, 187, 40);
}
#allMeterTree .nav-item i.green {
  color: rgb(33, 255, 4);
}
.ToggleNav {
  background: #57c32b;
  text-align: center;
  margin-bottom: 10px;
  display: inline-block;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  color: #fff;
  -webkit-transition: all 0.3ms;
  -moz-transition: all 0.3ms;
  transition: all 0.3ms;
}
.ToggleNav i {
  padding: 5px 10px;
}
a.ToggleNav {
  color: #fff !important;
}
#allMeterTree .nav-item i.fa {
  font-family: 'FontAwesome' !important;
  font-weight: normal;
}

#sidebar-wrapper {
  border-radius: 7px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
}
#sidebarToggle {
  position: relative;
  left: -10px;
}
