.pageBackHome
{
    background: #fff;
    padding: 15px 10px;
    border-radius: 7px;
    -moz-box-shadow: 0 2px 4px 0px #dddcdc;
    -webkit-box-shadow: 0 2px 4px 0px #dddcdc;
    box-shadow: 0 2px 4px 0px #dddcdc;
    overflow-y: auto;
    overflow-x: hidden;
    border: solid 1px #ebebeb;
    height: 100%;
  
}
.HomePage .ChartMoreOption
{
    display:none
}
.HomePage .climate {
    display:none
}
.HomePage .city {
    display:none
}
.HomePage .type {
    display:none
}
.HomePage .area {
    display:none
}
.HomePage .EULTitle
{
    display:none
}

.HomePage .eu-energy-label-class div
{
    margin-bottom: 0.3rem;
}
.HomePage .EULTable  {
min-width: 22rem;
}
.HomePage .EULTable .consumtionnumber,
.HomePage .EULTable .EnergyGrade,
.HomePage .EULTable .eActual{
    font-size: 0.75rem;
}
.HomePage .EULTable .consumtionnumber td,
.HomePage .EULTable .EnergyGrade td,
.HomePage .EULTable .eActual td{
   padding:0.5rem 0.3rem
}
.HomePage .HomeTitle{
    color: #000;
    margin-bottom: 1.8rem;
    font-weight: bold;
    padding: 0rem 0.5rem;
    font-size: 1rem;
}
.HomePage .h50
{
    height:48%;
}
.HomePage .consBox
{
    height:47%;
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
    border-radius: 7px;
    padding: 10px;
    color:#fff;
    text-align: right;
}
.HomePage .consBox h3{
    font-size: 1.1rem;
    color: #fff;
    margin-bottom: 0.2rem;
}
.HomePage .consBox h4{
    font-size: 0.8rem;
    color: #fff;
}
.HomePage .consBox h6{
    font-size: 1.8rem;
    color: #fff;
    margin-bottom: 2rem;
    margin-top: 0.5rem;
    padding:5px
}
.HomePage .consBoxOne
{
    background: #f1416c;

}
.HomePage  .consBoxTwo
{
    background: #57c32b;
    margin-top: 1.4rem;
}
.pageBackTransparent{
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    margin-bottom: 2rem;
}
.HomePage  .consumtionnumber
{
    display: none;
}
.tblSimple
{
    font-size: 0.9rem;
}
.tblSimple thead  tr
{
    font-weight: bold;
    color:#a5a5a5
}
.tblSimple thead  tr th
{
font-size: 1rem;
}
.tblSimple tbody tr
{
    color:#000
}
.tblSimple  th{
    border-bottom:solid 1px #e3e3e3 !important
}
.tblSimple td{
    border-bottom:solid 1px #e3e3e3 !important
}
.EventTblTitle{
    font-size: 1rem;
    color: #57c32b;
    font-weight: bold;
    margin-bottom: 1rem;
}
.eventBox .rightBrd
{
    border-radius: 10px;
    height: 10px;
    width: 10px;
    display: inline-block;
    content: '';
    background-color: #57c32b;
}
.EULTable  table{
    margin-bottom: 0px;
}
.HomePage .eventName
{
    margin-right: 10px;
    font-weight: bold;
    font-size: 0.8rem;
}
.HomePage .evenRow td{
padding: 0px
}
.HomePage  .eventDate{
    font-size: 0.85rem;
}
.HomePage .EULTitle, .HomePage  .EULText{
    font-size: 0.8rem;
}
.HomePage .standardLogo img{
    width:35%
}
.HomePage .EULTable 
{
    margin-top: 0.3rem;
}
.HomePage .standardTopRow td{
    padding:0.4rem

}
.HomePage .HomeTitleUnder{
    font-size: 0.9rem;
    margin-bottom: 10px;
}
.HomePage .todayEvents .row
{
    margin-bottom: 0.5rem;
    border-bottom: solid 1px #e3e3e3 !important;
    padding: 0.5rem;
    margin: 0px 3px;
}