.validAxie {
  background-color: #a9e0a9 !important;
}
.invalidAxie {
  background-color: #f7bcb8 !important;
}
[placeholder]:empty::before {
  content: attr(placeholder);
  color: #555;
  font-style: italic;
}
