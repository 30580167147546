.dpLtr
{
  direction: ltr;
}
.dpRtl
{
  direction: rtl;
}
.dpInputGroupText
{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.dpInputControl
{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.dpInputControl br{
  margin-bottom: 10px;
}
