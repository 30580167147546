.dp-card{
  padding: 2rem;
}
.dp-card Container{
  margin-top: 2rem;
}
.m-2{
  margin-top: 2rem;
}
.spinnerChart
{
  width: 200px;
  height: 200px;
}
.uploadInfo .noteInput
{
  top:1rem;
  right: 18.4rem;
}
#MeterData  .simpleBoxTitle {
  min-width: 32%;
}
.uploadInfo .simpleBoxTitle span{
  font-size: 0.9rem;
  padding-right: 3px;
}
.chooseFiled select{
  width:22%;
  font-size: 1rem;
  color: #5e5e5e;
}
.GreenTable a:hover{
  color:#626262
}
.chooseFiled li{
  margin-bottom:3px;
}
.uploadBtn .disabled{
  cursor: not-allowed;
}