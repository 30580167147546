.btnPeriod {
  width: 8rem !important;
}
.activeBtn {
  z-index: 1;
}
.calendarWidth {
  width: 100%;
  font-size: 0.6rem;
}
.ChartControlPanel ul.ChartType {
  margin-bottom: 4rem;
  padding: 0px;
}
.ChartControlPanel .ChartType li {
  float: right;
  list-style: none;
  text-align: center;
  padding: 0px;
}
.ChartControlPanel .ChartType li a {
  background-color: #e7e7e7;
  border: solid 1px rgb(207 206 206);
  padding: 5px;
  color: #919191 !important;
  display: inline-block;
  border-radius: 7px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  transition: all 0.3ms;
  -moz-transition: all 0.3ms;
  -webkit-transition: all 0.3ms;
  margin: 5px;
}
.ChartControlPanel .ChartType li a:hover {
  background-color: #57c32b;
  border: solid 1px #57c32b;
  color: #fff !important;
}
.ChartControlPanel .ChartType li a:hover i {
  color: #fff !important;
}
.ChartControlPanel .ChartType li a i {
  font-size: 1.2rem;
  color: #2e2e2e;
}
.ChartControlPanel .btn-active,
.ChartControlPanel .activeBtn {
  border-color: #57c32b !important;
  background-color: #57c32b !important;
  color: #ffffff;
  box-shadow: none;
}

.ChartControlPanel .btn {
  margin-bottom: 0rem;
  padding: 11px 8px;
}
.ChartControlPanel .btn,
.ChartControlPanel .btn:hover,
.ChartControlPanel .btn:focus,
.ChartControlPanel .btn:active {
  font-size: 0.8rem;
  font-weight: normal !important;
  width: 100%;

  text-align: center;
}
.ChartControlPanel .btn:hover,
.ChartControlPanel .btn:focus,
.ChartControlPanel .btn:active {
  font-size: 0.8rem;
  font-weight: normal !important;
  width: 100%;
  color: #333;
}
.ChartControlPanel .btn-simple {
  background-color: #e7e7e7 !important;
  border: solid 1px rgb(207 206 206);
  color: #000;
  border-color: rgb(207 206 206) !important;
  width: 100%;
  padding: 10px 7px;
}
.ChartControlPanel .btn-simple:hover {
  background-color: #57c32b !important;
  /* border: solid 1px  #57c32b !important; */
  color: #fff !important;
  box-shadow: none;
  width: 100%;
}
select {
  border: solid 1px #dcd9d9;
  font-size: 0.9rem;
  height: 30px;
  border-radius: 7px;
}
select.ControlPanel {
  width: 100%;
}
.f1 {
  font-size: 0.9rem !important;
}
.Calendar__day.-selected,
.Calendar__day.-selectedStart,
.Calendar__day.-selectedEnd {
  background: #57c32b !important;
}
.Calendar__day.-selectedBetween {
  color: #630d09 !important;
}
.Calendar__day.-selectedBetween {
  background: #57c32b6e !important;
}
a.activeBtn,
.activeBtn i {
  color: #fff !important;
}
.noRaduis .btn {
  border-radius: 0px !important;
}
.brdBottom {
  /* border-bottom: solid 1px #d7d7d7; */
  /* margin-bottom: 1rem; */
}
.ChartControlPanel {
  /* min-height: 36rem; */
}
.chartsFeaturesBox {
  padding: 8px 5px 10px 5px;
  border: solid 1px #cfcece;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin-top: 10px;
  transition: all 0.3ms;
  -moz-transition: all 0.3ms;
  -webkit-transition: all 0.3ms;
}
.chartsFeatures {
  font-size: 0.9rem;
  padding: 0px;
  transition: all 0.3ms;
  -moz-transition: all 0.3ms;
  -webkit-transition: all 0.3ms;
}
.chartsFeatures:hover {
  cursor: pointer;
}

.ChartControlPanel ul.panelIcons {
  margin-bottom: 4rem;
  padding: 0px;
}
.ChartControlPanel .panelIcons li {
  float: right;
  list-style: none;
  text-align: center;
  padding: 0px;
}
.ChartControlPanel .panelIcons li a {
  background-color: #e7e7e7;
  border: solid 1px rgb(207 206 206);
  padding: 5px;
  color: #919191 !important;
  display: inline-block;
  border-radius: 7px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  transition: all 0.3ms;
  -moz-transition: all 0.3ms;
  -webkit-transition: all 0.3ms;
  margin: 5px;
}
.ChartControlPanel .panelIcons li a:hover {
  background-color: #57c32b;
  border: solid 1px #57c32b;
  color: #fff !important;
}
.ChartControlPanel .panelIcons li a:hover i {
  color: #fff !important;
}
.ChartControlPanel .panelIcons li a i {
  font-size: 1.2rem;
  color: #2e2e2e;
}
.ChartControlPanel .costIcons li a {
  min-width: 50px;
  margin: 0px 4px 0px 4px;
}
.ChartControlPanel ul.costIcons {
  margin-bottom: 1rem !important;
}
.costIcons a.activeBtn,
.costIcons a.btn-active {
  color: #ffffff !important;
  box-shadow: none;
}
.ChartControlPanel .panelIcons li a.btn-active {
  color: #fff !important;
}
a.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
.ChartControlPanel {
  margin-top: 1rem;
}
.d-inherit {
  display: inherit !important;
}
