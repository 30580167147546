.IconSelect select{
    font-family: 'FontAwesome';
}
.circle-picker 
{
  width:100% !important
}
select.IconSelect
{
  font-family: 'FontAwesome' !important;
}