.predicted {
  direction: ltr;
  color: #6e61ed;
}
.actual {
  direction: ltr;
  color: #57c32b;
}
.saving {
  direction: ltr;
  font-weight: bold;
}
