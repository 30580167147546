.mt-10{
    margin-top: 10rem;
}

.fromDate .DatePicker  input, .toDate .DatePicker  input{
    width:100%;
    height:33px
}
.fromDate .DatePicker , .toDate .DatePicker  {
    width:100%
}
.fromDate
{
    margin-top: 4rem;
}
.notification
{
    font-size: 1rem;
}