.font100
{
    font-size: 30px;
}
.NewReport .col-form-label
{
    font-size: 1rem;
}
.pagePrint
{
    visibility: hidden;
    height: 0px;
}

