
@media print {

  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }

  html body {
    background-color: #fff;
    padding: 0px;
    margin-top: 0px;
    font-family: 'Iranian Sans';
    box-shadow: none;
  }
  .PrintFooter {
    position: fixed;
    bottom: 0;
    height: auto;
    width: 100%;
    z-index: 1000;
    overflow-y: none;
  }
  .PrintHeader {
    position: fixed;
    top: 0;
    height: 100px;
    width: 300px;
    z-index: 1000;
    overflow-y: none;
  }
  .EULTable .table-bordered,
  table {
    border-collapse: unset;
    border-width: 1px 0 0 1px !important;
  }
  /* .ReportTabs{
    display: none;
    } */
  .ReportTabs nav,
  .NewReport {
    display: none;
  }
  .Printbtn {
    display: none;
  }
  .pagePrint {
    display: block !important;
    visibility: visible;
    width: 100%;
        /* margin-bottom:180px;
      margin-top: 180px; */
      height: 78%;
  }
  .prBox {
    page-break-after: always;
    margin-top: 100px;
  }
  .EULTable {
    margin-top: 0px;
    padding-top: 0px;
  }
  .horizontal-menu .horizontal-menu-wrapper {
    border-bottom: 0px;
    border: 0px;
    background: #fff;
  }
  .navbar-floating .header-navbar-shadow {
    background: #fff;
  }
  .prBox thead.headTable th {
    font-size: 1.7rem;
    font-weight: bold;
  }
  .prBox tr.trTable
  {
    font-size: 1rem;
  }
  .table-bordered
  {
    border-color:#878787
  }
  .prBox .printTable tr ,.prBox .printTable tr td{
    font-size: 1.5rem;
    font-weight: normal;
  }

  .printChartReport canvas{
    margin:1cm auto;
    width:1000px !important;
    min-height: 350px;
  }
  .printChartReport div canvas{
    width:100% !important;
    height: 100%;
  }
  .printChartReport 
  {
    width:100%;
  }
  .firtChart canvas
  {
    margin-top:5rem;
  }
  .prBox h1{
  font-size: 2.2rem;
  margin-bottom:2rem;
  margin-top: 2rem;
  }
    .fixed-bottom {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      margin-top: 20rem;
    }
    .reportImage{
      margin-top: 7rem;
      text-align: center;
    }
    .propBox .fontTd{
      font-size: 0.9rem;
    }
}


.printChartReport
{
  max-width:19cm
}
.printChartReport div canvas{
  width:100% !important;
  height: 100%;
}
/* .hidePrint {
  display: none;
} */

@media screen {

  .PrintFooter,
  .PrintHeader {
    display: none;
  }
  .printChartReport canvas
  {
    margin-top:10px;
  } 
}
