.dp-card{
  padding: 2rem;
}
.dp-card Container{
  margin-top: 2rem;
}
.active .bs-stepper-circle{
  background-color:#cf271e;
}
.bs-stepper .step-trigger:focus{
 color: #6c757d;; 
}
.bs-stepper-header
{
  margin-bottom: 3rem;
}
.btn-red{
  background-color: #57c32b  !important;
  border: solid 1px  #57c32b !important;
  color: #fff !important;
  box-shadow: none;
  border-color: #57c32b !important;
}
.btn-red:hover{
  box-shadow: none;
  border-color: #57c32b !important;
}
.StepperBread .breadcrumb .breadcrumb-item
{
  font-family: 'Iranian Sans' !important;
}
.StepperBread .breadcrumb .breadcrumb-item span.lbl{
  padding-left:5px;
  font-weight: bold;
  color:#343434;
}
.StepperBread .breadcrumb .breadcrumb-item + .breadcrumb-item:before
{
  content:"/"
}
.StepperBread .breadcrumb{
  font-size:0.9rem;
  font-family: 'Iranian Sans';
  padding: 0.5rem 1rem 0.5rem 0 !important;
  border: 1px solid #eaeced;
  border-radius: 0;
  margin-bottom: 3rem;
}
.PassStep .bs-stepper-circle
{
background: #06b51c;
}
.PassStep .bs-stepper-label
{
  color:#06b51c;
}
.notActive
{
  pointer-events: none;
}
.hideBox
{
  display:none;
}
.showBox
{
  display:block
}
select.IconSelect
{
  font-family: 'FontAwesome' !important;
}
.circle-picker 
{
  width:100% !important
}
button.chooseType
{
  min-width: 175px;
}
#stepper-l-2 h3{
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 1rem;
}
.NewSitebox
{
  background: #ebebeb;
  padding: 30px 0px;
  border: solid 1px #c9c6c6;
}
.GreenTable .thead-dark th
{
  background-color: #57c32b;
    border-color: #57c32b;
    font-family: tahoma;
    font-size: 1rem;
}

