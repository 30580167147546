.left-navbar-submenu
{
  direction: ltr;
}

.left-navbar-submenu .nav-item i
{
  color: #2e2e2e;
}
.left-navbar-submenu ul li i
{
  font-size: 1.7rem;
  padding: 6px;
  color: #2e2e2e;
}
.subMenuActive i
{
  color: #57c32b !important;
  border-radius: 4px;
  font-size: 18px;
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li.active > a
{
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.left-navbar-submenu .nav 
{
  padding:0px;
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li.active > a
{
  background: #57c32b;
}
.left-navbar-submenu ul li{
  -moz-transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}
.left-navbar-submenu ul li  a:hover i{
  color:#57c32b;
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > a
{
  color: #333;
  font-weight: bold;
}

.badge.badge-primary
{
  background:#57c32b
}

