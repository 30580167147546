.greenHeader
{
    background:#57c32b ;
    text-align: center;
    color:#fff;
    padding: 20px 10px;
}
.greenHeader p
{
    padding:18px 10px 10px 10px
}