.spinnerChart
{
  width: 200px;
  height: 200px;
}
.toolBarShow{
  position: absolute;
  left: 32px;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
  transition: all 400ms;
  cursor:pointer;
}
.badge.badge-info
{
  background-color: #cf271e85;
    font-size: 0.9rem;
    padding: 1.5rem 1rem;
    border:solid 1px #e18580;
}
.badge-info i{
  font-size: 2rem;
}
.AllNotes
{
  margin-top:3rem
}
.AllNotes ul li
{
  list-style: none;
  border: solid 1px #ddd;
  padding: 10px;
  -moz-border-radius:7px;
  -webkit-border-radius:7px;
  border-radius:7px;
  margin-top:1rem;
  margin-bottom:1rem;
}
.AllNotes ul
{margin:0px;
padding:0px;}
.ComDate
{
  font-size: 0.8rem;
  color: #606060;
  margin-right: 5px;
}
.ComUserName{
  color: #333;
  font-weight: bold;
  margin-right: 10px;
  font-size: 1rem;
}
.ComTxt{
  font-size: 1rem;
  margin-top: 5px;
  color:#333
}
.ComAction a
{
  font-size: 0.8rem;
  color: #5e5e5e !important;
}
.ComAction i{
  padding-left: 3px;
}
.ComAction a:hover
{
color:#57c32b !important;
-webkit-transition: all 400ms;
-moz-transition: all 400ms;
-ms-transition: all 400ms;
-o-transition: all 400ms;
transition: all 400ms;
}
.active
{
  display:block;
}
.hide
{  display:none;}
.AllNotes ul li.parentChild
{
  background: #ebebeb;
}
.AllNotes ul li.childNote
{
  background: #f5f5f5;
  
}
.ChartPrintIcon  .printIcon{
  position: absolute;
  top:0rem;
  right:3rem
}
.react-contextmenu-wrapper canvas{
  width:100% !important
}




