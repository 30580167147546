.deleteRA {
  float: left;
  font-size: 20px;
  color: #e2334c;
}
.lblTab {
  color: #57c32b;
}
.tab-content {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0px 0px 5px 5px;
  padding: 10px;
}

.nav-tabs {
  margin-bottom: 0 !important;
  border-bottom: 1px solid #dae1e7 !important;
}
