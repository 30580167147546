h2{
    border-bottom: solid 3px #57c32b;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
}
.mv .lbl {
    font-weight: bold;
    padding-left: 0.5rem;
    font-size: 1rem;
}
.mv .text {
    font-size: 1rem;
}
.mv .subTitle
{
    font-weight: bold;
    margin-bottom: 1rem;
}
.mv .public-DraftStyleDefault-block:first-child{
    margin-top: 0px;
}