.JobDescriptionImage{
    margin:5px
}
.JobDescription .ag-row .ag-cell {
    display: flex;
  /*  justify-content: center;  align horizontal */
    align-items: center;
  }
  .FilePreview div i
  {
    color:#626262 !important;
    cursor: pointer;
  }
  @media (min-width: 576px){
  .JobDescriptionModal .modal-dialog {
    max-width: 1000px;

}
}