#notifications .scrollable-container
{
    overflow-y: scroll;
}
#notifications h3
{
    font-family: 'Iranian Sans';
    font-size: 1.2rem;
}
#notifications .media-heading
{
font-size: 1rem;
}
#notifications .time
{
    padding: 0px 6px;
    font-size: 0.9rem;
    direction:ltr
}
#notifications .dropdown-header{
    background:#656565;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
}
#notifications .dropdown-notification .notification-text
{
    font-size: 0.9rem;
    color: #121212;
}
.dropdown-notification .dropdown-menu.dropdown-menu-right::before
{
    background:#656565;
    border-color: #656565;
}
.dropdown #notifications.dropdown-menu{
    box-shadow: 0 5px 25px rgb(0 0 0 / 30%);
}
 .header-navbar .navbar-container ul.nav li #notifications .media-list{
    min-height: 22rem;
}
.HideNotification
{
    background: #e1dfdf;
    text-align: center;
    padding: 0px 3px;
    border-radius: 4px;
    color: #333;
}
.HideNotification:hover{
    background:#b9b8b8 ;
    -webkit-transition: all 400ms;
-moz-transition: all 400ms;
-ms-transition: all 400ms;
-o-transition: all 400ms;
transition: all 400ms;
cursor: pointer;
}
#notifications  .media-list .media .media-left {

padding-left: 0.3rem;}

