
.contextNav {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  color: #373a3c;
  font-size: 12px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: right;
  transition: opacity 250ms ease !important;
}
hr.solid {
  border-top: 3px solid #bbb;
}

.contextItem:hover{
  display: block;
  background-color: #ddd;
  text-align: center;
  padding: 5px;
}
.contextItem{
  text-align: center;
  padding: 5px;
}
.ChartMoreOption
{
  position: absolute;
  top: 0rem;
  right:10px;
font-size: 1.4rem;
padding:2px 4px;
border-radius: 4px;
}
.ChartMoreOption:hover{
  background:#ddd;
  cursor: pointer;
}
.printChart canvas
{
  margin-top:10px;
}
@media print
{
  .printChart canvas{
    margin:2cm auto;
    width:1000px !important;
    min-height: 350px;
  }
  .ChartMoreOption
{
  display: none;
}
}
.printChart .contextItem:hover
{
  cursor: pointer;
}