.ReportTabs .tab-content
{
    border:0px
}
.ReportTabs nav{
    background: #626262;
    color: #fff;
    padding: 0px 10px 0px 10px;
    -moz-border-radius: 7px !important;
    -webkit-border-radius: 7px !important;
    border-radius: 7px !important;
    border:none;
    min-height:47px;
    margin-bottom: 5rem !important;
}  
.ReportTabs nav  a{
    color: #fff;
    font-size: 0.9rem;
    text-align: center;
    padding: 10px 10px 12px 10px ;
    margin: 0px 5px;
    border:none;
    transition: all 0.3s ease ;
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    border-radius: 0px !important;
    border-top: solid 4px #626262;
}

.ReportTabs  a.nav-link:hover{
    /* background: #a1a1a1be; */
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    border-radius: 0px !important;
    /* border-top: solid 4px #626262; */
         border-top-color: #626262;
         border-bottom-color: #fff;
         border-right-color: #626262;
         border-left-color: #626262;
         color:#cfcfcf;

}
.ReportTabs  a.nav-link.active {
    color: #4E5154;
    background-color: #fff;
    border: none;
    border-top: solid 6px #57c32b;

}
#ReportPage
{
    color:#000
}
#ReportPage .descFont
{
    font-size: 0.9rem;
    text-align: justify;
}
#ReportPage .IconFont i
{
    font-size: 1.5rem;
}
#ReportPage .title
{
    font-size: 1.1rem;
    text-align: right;
}
#ReportCheckBox{
    margin-right: -2px;
    margin-top: -5px;
}
#ReportPage tr , #ReportPage table ,  #ReportPage td
{
   border: solid 2px #dfdcdc
}
#ReportPage td
{
   text-align: center;
}
#ReportPage .subTitle
{
    color: #666;
    font-size: 0.8rem;
}
.unitRadio  .form-check
{
    display: inline-block;
}
.unitRadio 
{
    font-size: 0.8rem;
    margin-bottom: 5px;
}
.NewReport  #sidebar-wrapper{
    min-height:30vh  !important;
}

.grayback
{
    background-color: #ededed;
    padding: 5px;
    min-height: 45vh;
    border: solid 2px #dfdcdc;
   
}
.grayback label
{
    font-weight: bold;
    font-size: 0.9rem !important;
    background: #d5d5d5;
    margin-bottom: 1rem;
    display: block;
}
.grayback .scrollBox{
    overflow-y: scroll;
    /* height: 40vh; */
    height: 45vh;
}
.savebtn{
    margin:2rem auto;
    text-align: center;
}

