.ModalNewForm .col-form-label
{
    font-size: 12px;
}
.custom-select
{
    font-family: 'Iranian Sans' !important;
    font-size: 0.9rem;
}
.css-b62m3t-container
{
    font-size: 0.9rem;
}
.css-1s2u09g-control
{
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    
}
.BTCustomPos .noteInput
{
    top:0.7rem;
    right:-0.5rem
}
