#rightNav .SelectedItem
{background:#57c32b;
    -webkit-transition:all 0.3ms;
    -moz-transition:all 0.3ms;
    transition:all 0.3ms;
    color:#fff}
#rightNav .SelectedItem:hover
{background:#57c32b;
    background-color: #57c32b;}
#rightNav .SelectedItem  span, #rightNav .SelectedItem a span{
    color:#fff !important
}
#rightNav .SelectedItem  span > i , #rightNav .SelectedItem a span> i
{
  color:#333 !important
}
#rightNav .Navbox.SelectedItem:hover{
    
    background-color: #57c32b;
  }
#rightNav .nav-link[data-toggle].collapsed:before {
    content: " ▾";
    padding-left: 3px;
      font-size: 1.2rem;
      color: #121212;
      position: relative;
      top: -6px;
      right: -12px;
      padding: 10px;
  }
  #rightNav .nav-link[data-toggle]:not(.collapsed):before {
    content: " ▴";
    padding-left: 3px;
      font-size: 1.2rem;
      color: #a5a5a5;
      position: relative;
      top: -6px;
      right: -12px;
      padding: 10px;
  }
  #rightNav .nav-item > li{
    padding: 10px 0px;
  }
  #rightNav .nav-item > li > .nav-item > li{
    padding: 0px 0px;
  }
  #rightNav .nav-item a
  {
    display:inline-block;
  }
  #rightNav .nav-item  i 
  {
    padding-left: 0.5rem;
  }
  #rightNav .nav-item  span
  {
    font-size:1rem;
    color:#333;
  }
  #rightNav .nav-item
  {
    margin:3px 0px;
  }
  #rightNav .navClick{
    display: inline-block;
  cursor:context-menu;
  padding-left: 0.5rem;
  }
  #rightNav .Navbox
  {
      padding:5px;
  }
  #rightNav .Navbox:hover{
    
    background-color: #f9f9f9;
  }
  #rightNav .nav-item a ,#rightNav .nav-item a span
  {
    font-size:0.93em;
    color:#121212;
    width:90%;
    font-weight: bold;
  }
  #rightNav .nav-item span{
    font-size:0.93rem !important
  }
  #rightNav .nav-item .nav-link
  {
    padding:0rem;
    height: 10px;
    width:10px
  }
  #rightNav .nav-item  i.red{
  color:red;
  }
  #rightNav .nav-item  i.orange{
    color:rgb(255, 187, 40);
    }
  #rightNav .nav-item  i.green{
      color:rgb(33, 255, 4);
  }
  .ToggleNav{
      background: #57c32b;
      text-align: center;
      margin-bottom: 10px;
      display: inline-block;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      color: #fff;
      -webkit-transition:all 0.3ms;
      -moz-transition:all 0.3ms;
      transition:all 0.3ms;
  }
  .ToggleNav i{
    padding: 5px 10px;
  }
  a.ToggleNav
  {
    color:#fff !important;
  }
  #rightNav .nav-item i.fa
  {
    font-family: 'FontAwesome' !important;
    font-weight: normal;
  }
  
  #sidebar-wrapper {
border-radius: 7px;
-moz-border-radius: 7px;
-webkit-border-radius: 7px;
  }
  #sidebarToggle
  {    position: relative;
    left: -10px;}
  