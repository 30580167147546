.fc .fc-toolbar-title
{
    font-size: 1.4rem;
}
/* .fc .fc-button-primary:not(:disabled){
    border-color:#57c32b;
    background-color:#57c32b;
} */
.fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):focus
{
    border-color:transparent !important;
    background-color:#57c32b;
}
.fc .fc-button-primary{
    border-color:transparent;
}
.fc-direction-rtl .fc-daygrid-event .fc-event-time, .fc-daygrid-dot-event .fc-event-title
{
    color:#000;
    font-size: 0.8rem;
}
.font1
{
    font-size:1rem
}
.fc-dayGridDay-view .fc-daygrid-event .fc-event-title
{
    font-size: 1rem;
}
.fc-dayGridDay-view .fc-daygrid-event
{
    margin-bottom: 5px;
    padding: 3px 5px;
}
.colorLbl i
{
    color:#57c32b;
    margin-left:5px;
}
.w100
{
    width:100%
}
h2.fc-toolbar-title
{
    display: inline-block;
    padding: 0px 10px;
}
.fc-nextYear-button
{
    margin-right: 5px !important;
}
.fc-prevYear-button
{
    margin-left:5px !important;
}
.fc .fc-daygrid-day.fc-day-today {
    background-color: #57c32b47 !important;
} 
