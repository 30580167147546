.printENergyLable .printIcon
{
    position: absolute;
    left: 15px;
}
.EULTable 
{
    margin-top: 2rem;
}
.marginAuto{
    margin:0 auto
}