.DPDTConflict td {
  text-align: right;
}

.DPDTConflict .p-paginator {
  direction: ltr !important;
}

.DPDTConflict .p-paginator-page.p-highlight {
  font-weight: 600 !important;
  color: darkgreen;
}
