.toggleTrend {
  /* width: 100%; */
  text-align: center;
  border-bottom: solid 1px #c5c5c5;
  padding-bottom: 7px;
}
.toggleTrend div {
  width: 100%;
}
.trendSteps {
  cursor: pointer;
  border: 2px solid #bdd1cb;
  margin: 10px auto;
  background: #fff;
  margin-bottom: 1.5rem;
  padding: 0px 5px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  -webkit-transition:all 0.3ms;
  -moz-transition:all 0.3ms;
  transition:all 0.3ms;
}

.trendSteps div {
  width: 100%;
  height: 45px;
}
.trendSteps div span {
  position: relative;
  top: 11px;
  font-size: 0.9rem;
}
.activeStep {
  font-weight: bold;
  background-color: #d9f4eb !important;}
.regressionFormula {
  direction: ltr;
  border: 3px solid #bdd1cb;
  text-align: center;
  margin-bottom: 2rem;
  padding: 20px 10px;
  margin-top: 2rem;
  background: #f7f7f7;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  color:#000
}
.regressionParam{
  direction: ltr;
  border: 1px solid #bdd1cb;
  text-align: center;

}
.regParamTitle{
  background: #57c32b;
    color: #fff;
    padding: 10px;
    font-size: 1.1rem;

}
.regParamText{
  padding: 28px 10px;
}
.custom-control-input:checked ~ .custom-control-label::before
{
  border-color: #57c32b;
  background-color: #57c32b;
}
.activeStep
{
  font-weight: normal;
  background-color: #57c32b !important;
  color: #fff;
  font-size: 0.9rem;
  border: 2px solid #57c32b;
}
.activeStep .trendStepsNumber{
  border: solid 2px #fff;
color:#fff
}
.trendStepsNumber
{
  width: 24px;
  border: solid 2px #767676;
  height: 25px;
  border-radius: 100%;
  display: inline-block;
  margin-left: 8px;
  text-align: center;
  font-size: 0.9rem;
  color: #767676;
  text-align: center;
}
.regressionOrderSelect
{width:20%;
text-align: center;}
.TrendSelect
{width:100%;
  text-align: center;}
.boxTitle{
  font-size: 1.2rem;
  text-align: center;
  background: #57c32b;
  padding: 10px;
  color: #fff;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  margin-bottom: 1rem;
}
.simpleBoxTitle{
  font-size: 1.2rem;
    text-align: center;
    padding: 10px;
    color: #686868;
    margin-bottom: 1rem;
    margin-top: 2rem;
    border-right: solid 9px #57c32b;
    display: inline-block;
    text-align: center;
    background: #f1f1f1;
    min-width: 29%;
    text-align: right;
    color:#000
}
.simpleBoxTitleRed
{
  border-right: solid 9px #cc0000;
  font-size: 1.2rem;
  text-align: center;
  padding: 10px;
  color: #686868;
  margin-bottom: 1rem;
  margin-top: 2rem;
  display: inline-block;
  text-align: center;
  background: #f1f1f1;
  min-width: 29%;
  text-align: right;
  color:#000
}
.RegTable
{
  direction: ltr;
  text-align: center;
}
.RegTable tr
{
  border:solid 2px #cfcfcf
}
.RegTable thead{
  border-bottom: solid 3px #cfcfcf;
  font-family: 'tahoma' !important;
  font-weight: bold;
}
.RegTable thead tr th 
{
  font-size: 1rem;
}
.formulX
{
  font-size: 1rem;
  color:#57c32b;
  font-weight: bold;
}
.formulY
{
  font-size: 1rem;
  font-weight: bold;
}
.TrendPage canvas{
  width:100% !important
}