.ltrForm select , .ltrForm input{
    text-align: left;
    font-family: tahoma;
}
.ResultBox
{
    margin-bottom: 2rem;
    margin-top: 2rem;
    font-size: 1.3rem;
    font-family: tahoma !important;
}
.ResultBox .red
{
    color: #ff0b0b;
    font-weight: bold;
    margin-right: 10px;
    font-size: 1.5rem;
}
.unitBox 
{
    background-color: #dfdfdf;
    padding: 21px 10px;
    border: solid 1px #c1c1c1;
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
    border-radius: 7px;
}
.unitBox .custom-select{
    margin-top: 1.3rem;
    direction: ltr !important;
}
.unitBox .custom-select option
{
    font-size: 1.1rem;
    padding: 4px 4px;
    color:#000
}
.unitBox input
{
color:#000;
font-size: 1.1rem;
}
.unitBox select {
    height: auto;
}