.h100 {
  height: 100%;
}
.ltr {
  direction: ltr;
}
@media print {
  .dpPrint {
    display: block;
  }
}
