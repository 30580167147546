#DocRightNav 
{
    margin-top: 1rem;
}
#DocRightNav .SelectedItem
{background:#027450;
    -webkit-transition:all 0.3ms;
    -moz-transition:all 0.3ms;
    transition:all 0.3ms;
    color:#fff;}
    #DocRightNav ul.flex-column .nav-item 
{
    padding-right:1rem;
    color:#000;
}
#DocRightNav .nav-item
{
  margin:3px 0px;
}
#DocRightNav .SelectedItem:hover
{background:#f9f9f9;
   color:#333}
#DocRightNav .SelectedItem  span, #DocRightNav .SelectedItem a span{
    color:#fff !important;

}
#DocRightNav .SelectedItem  span > i , #DocRightNav .SelectedItem a span> i
{
  color:#fff !important
}
#DocRightNav .Navbox.SelectedItem:hover{
    
  background:#f9f9f9;
  color:#333
  }
#DocRightNav .nav-link[data-toggle].collapsed:before {
    content: " ▾";
    padding-left: 3px;
      font-size: 1.2rem;
      color: #a5a5a5;
      position: relative;
      top: 3px;
      right: 0px;
  }
  #DocRightNav .nav-link[data-toggle]:not(.collapsed):before {
    content: " ▴";
    padding-left: 3px;
    font-size: 1.2rem;
    color: #a5a5a5;
    position: relative;
    top: 3px;
    right: 0px;
  }
  #DocRightNav .nav-item > li{
    padding: 10px 0px;
  }
  #DocRightNav .nav-item > li > .nav-item > li{
    padding: 0px 0px;
  }
  #DocRightNav .nav-item a
  {
    display:inline-block;
  }
  #DocRightNav .nav-item  i 
  {
    padding-left: 0.5rem;
  }
  #DocRightNav .nav-item  span
  {
    font-size:1rem;
    color:#333;
  }
 
  #DocRightNav .navClick{
    display: inline-block;
  cursor:context-menu;
  padding-left: 0.5rem;
  }
  #DocRightNav .Navbox
  {
      padding:5px;
  }
  #DocRightNav .Navbox:hover{
    
    background-color: #f9f9f9;
  }
  #DocRightNav .nav-item a ,#DocRightNav .nav-item a span
  {
    font-size:0.93em;
    color:#333;
    width:90%;
  }
  #DocRightNav .nav-item span {
    font-size:0.9rem !important;
  }
  #DocRightNav .nav-item .navclick
  {
    font-size:0.86rem;
    text-align: justify;
    cursor: pointer;
    padding: 3px 5px;
  }
  #DocRightNav .nav-item .navclick:hover
  {
    background: #ebebeb;
  }
  #DocRightNav .nav-item .nav-link
  {
    padding:0rem;
    cursor: default;

  }
  #DocRightNav .nav-item  i.red{
  color:red;
  }
  #DocRightNav .nav-item  i.orange{
    color:rgb(255, 187, 40);
    }
  #DocRightNav .nav-item  i.green{
      color:rgb(33, 255, 4);
  }
  .ToggleNav{
      background: #57c32b;
      text-align: center;
      margin-bottom: 10px;
      display: inline-block;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      color: #fff;
      -webkit-transition:all 0.3ms;
      -moz-transition:all 0.3ms;
      transition:all 0.3ms;
  }
  .ToggleNav i{
    padding: 5px 10px;
  }
  a.ToggleNav
  {
    color:#fff !important;
  }
  #DocRightNav .nav-item i.fa
  {
    font-family: 'FontAwesome' !important;
    font-weight: normal;
  }
  .btnViewDoc
  {
    font-size: 0.9rem;
  }

  .resetNav{
    background: #ededed;
    margin-top: 1rem;
    padding: 5px;
    text-align: center;
    border: solid 1px #d5d5d5;
    -webkit-transition:all 0.3ms;
    -moz-transition:all 0.3ms;
    transition:all 0.3ms;
  }
.resetNav:hover{
  cursor: pointer;
  background: #d3d3d3;
}